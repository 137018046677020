import React from 'react';
import "../UsersCollection/UsersCollection.css"

const Error = () => {
    return (
    <div className="collection-container">
         <h1>Please connect to metamask to view your nfts..</h1>
    </div>
    )
}

export default Error