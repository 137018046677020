import '../About/About.css'

function About() {
    return ( 
        
        <div className="about-container">
                <div className='about-item'>
                    <h3>Exposure</h3>
                    <p>
                    Depositing to The Vault only takes a few clicks and will introduce your artwork to a new audience.
                    </p> 
                </div>
                
                <div className='about-item'>
                    <h3>Collect</h3>
                    <p>Discover new art collections in a fun way, with the element of surprise!
                        
                    </p>
                </div>
                
                <div className='about-item'>
                    <h3>Community</h3>
                    <p> We're about building a community. Join our discord to make decisions on what nfts we purchase. 
                    </p>
                </div> 

                <div>
                    <div className="about-button"> <a href="https://drive.proton.me/urls/KNN9Q42JYG#XuIOcfsWnVlj" target="_blank" rel="noreferrer">Roadmap</a></div>
                   
                </div>   
        </div>
       

        
      );
}

export default About;