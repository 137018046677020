import "../Header/Header.css"

function HeaderInfo() {
    return ( 
        <>
            <h2 className="title">Collect<br/> randomly</h2> 
            <br/>
            <p className="text">
                Buy random nft. Donate nft. Proceeds go to charity.
            </p>
        </>
     );
}

export default HeaderInfo;